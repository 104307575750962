import {enUS} from 'date-fns/locale';

import {ROUTES_EN} from '../routes/en';
import {TIMEZONE_NAME} from '../timezone/spain';

// TODO us environent data
export const ENVIRONMENT_US = {
  id: 'us',
  fulldomain: 'web.tulotero.com',
  locale: {
    domain: '.us',
    locale: 'en-US',
    dateLocale: enUS,
    dateFormats: {
      format: 'MM/dd/yyyy',
      shortDayMonthYear: 'MM/dd/yy',
      dayMonthNameYear: 'MMM d, y',
      dayMonth: 'MMM. do',
      date: 'P',
      dateShort: 'dd MMM. - hh:mm a',
      dayNameDate: 'eeee, P',
      dayNameMonthDate: 'eee, MMM d',
      dayMonthTime: 'MMM. do, hh:mm a',
      shortMonth: 'MMM',
      time: "h:mm aaaaa'm'",
      shortDateAndTime: 'MM/dd/yyyy hh:mm a',
      dayName: 'eee',
    },
    zipCodeFormat: '00000',
    currencyCode: 'USD',
    timezone: TIMEZONE_NAME,
    partnersLogo: 'assets/img/logos/partners-logos-us.png',
    sponsorLogo: 'assets/img/menu/embajador-USD.svg',
    user: {
      defaultCountry: 'us',
      defaultPhoneCode: 'us',
      allowDNI: false,
      allowApostrophes: true,
    },
    bankAccountType: 'IBAN',
    menu: {
      downloadAndroidApp: true,
      downloadIosApp: true,
    },
    routes: ROUTES_EN,
  },
  app: {
    apkVerticalImage: true,
    ios: {
      id: '948041023',
      link: 'https://tulotero.com/download-ios',
    },
    android: {
      downloadUsingPlayStore: false,
      link: 'https://tulotero.com/download-android',
    },
    samsung: {},
  },
  fingerprint: {
    region: 'us',
    endpoint: 'https://fp.web.tulotero.com',
  },
  kyc: {
    showProfileDNI: false,
    allowSkip: false,
    methods: {
      default: 'dlidcard',
      voluntary: true,
    },
    checkStartMethod: true,
    showLegalTermsSMS: true,
    showKycStepPassport: true,
  },
  mobile: {
    headers: {
      main: {
        hiddenHeaderDescriptionMainMobile: true,
      },
    },
  },
  money: {fraudSystem: false, loadMin: 0.01, defaultPageDesktop: 'PAYMENTS'},
  selfControl: {
    removeLimits: false,
  },
  menu: {
    background: 'assets/img/mobile/menu/background.jpg',
    backgroundChristmas: 'assets/img/mobile/menu/background-christmas.jpg',
  },
  profile: {
    background: 'assets/img/mobile/perfil/background.jpg',
    backgroundChristmas: 'assets/img/mobile/perfil/background-christmas.jpg',
    hiddenRequiredData: true,
  },
  init: {
    screenLogo: 'assets/img/mobile/screen-android-en-us-colorado.png',
  },
  balance: {
    showKycBanner: false,
  },
  groups: {
    enable: false,
    showInfoPrizes: false,
    shareUrl: 'https://tulotero.com/g/',
  },
  games: {
    play: {
      savedPlayTimeout: 900,
      requiredKyc: true,
    },
    results: {showCheckBanner: false},
  },
  preferences: {
    notificationCategory: 'notifications',
  },
  registration: {smsTimeout: {desktop: 60, mobile: 60}},
  geolocation: {
    highAccuracy: false,
    enableAppState: true,
    maxAccuracy: 5000000, // 5000km
    // En USA no se utiliza, se basa en la respuesta de backend NotEnoughAccuracy
    defaultAnonimousStateCode: 'CO',
    reduceLocationAddPayment: true,
  },
  social: {
    sponsorImg: 'assets/img/social/image-sponsor-us.png',
    useNativeSms: true,
  },
  // indicate whether the backend server is in UTC format
  serverOnUTC: true,
  features: {
    signUp: {
      // Show promo code input directly in sign up screen
      showPromoInput: true,
    },
    tickets: {
      filterGames: true,
      showTicketInfo: true,
    },
    trust: {
      showOfficialRetailer: true,
      showWinners: true,
      welcomeGift: true,
      ratings: true,
    },
    booths: {
      showRetailers: true,
      remoteBooths: true,
      distanceAlert: {near: 30, far: 200}, // milles
    },
    // Subscribe specific days and by jackpot
    subscribe: {
      enable: true,
      maxDaysSelectorBreakpoint: 'lg',
    },
  },
  maintenance: {redirectUrl: 'maintenance-us.html'},
};
